import {Image, ImageProps} from 'platform/foundation';

import {mapMarketToAdServer} from '../../utils/mapMarketToAdServer';

interface AdServerLogoProps {
  market: string;
  maxHeight: ImageProps['maxHeight'];
}

export function AdServerLogo(props: AdServerLogoProps) {
  const logoName = mapMarketToAdServer(props.market);

  return (
    <Image
      src={`/assets/images/ad-server-logo/${logoName}.svg`}
      maxHeight={props.maxHeight}
      fit="contain"
      position="left"
    />
  );
}
