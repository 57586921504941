import {match} from 'ts-pattern';

export const mapMarketToAdServer = (market: string): string =>
  match(market)
    .with('CZE', () => 's-auto')
    .with('DEU', () => 'mobile-de')
    .with('POL', () => 'otomoto')
    .with('AUT', () => 'autoscout')
    .with('ITA', () => 'autoscout')
    .with('SVK', () => 'autobazar-eu')
    .with('FRA', () => 'la-centrale')
    .with('SWE', () => 'blocket')
    .with('ROU', () => 'autovit')
    .with('BEL', () => 'autoscout')
    .otherwise(() => 'mobile-de');
