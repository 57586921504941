import {AdvancedMarker, InfoWindow, Pin, useAdvancedMarkerRef} from '@vis.gl/react-google-maps';
import {Show, VStack} from 'platform/foundation';
import {useTheme} from 'styled-components';

import {memo} from 'react';

import {PriceReportType} from '@price-report/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {ExtendedStatisticsCar} from '../types';
import {getChartItemColor} from '../utils/getChartItemColor';
import {CarTooltip} from './CarTooltip';

interface MarkerWithInfoProps extends RequiredTestIdProps {
  location: google.maps.LatLngLiteral;
  cars: ExtendedStatisticsCar[];
  priceReport: PriceReportType;
  isFocused: boolean;
  isSelected: boolean;
  onClick: (cars: ExtendedStatisticsCar[]) => void;
  onHover: () => void;
  onInfoClose: () => void;
}

export const MarkerWithInfo = memo(
  (props: MarkerWithInfoProps) => {
    const [markerRef, marker] = useAdvancedMarkerRef();
    const theme = useTheme();
    const color = getChartItemColor(theme, props.cars[0], props.isFocused, props.isSelected);

    return (
      <>
        <AdvancedMarker
          position={props.location}
          ref={markerRef}
          onClick={() => props.onClick(props.cars)}
          onMouseEnter={props.onHover}
        >
          <Pin
            glyph={props.cars.length.toString()}
            background={color}
            glyphColor="#000"
            borderColor="#000"
          />
        </AdvancedMarker>

        <Show when={props.isFocused}>
          <InfoWindow anchor={marker} onClose={props.onInfoClose}>
            <VStack spacing={2}>
              {props.cars.map((car) => (
                <CarTooltip
                  key={car.ad_id}
                  adId={car.ad_id}
                  isMyVehicle={car.isMyVehicle}
                  priceReport={props.priceReport}
                  data-testid={suffixTestId('carTooltip', props)}
                />
              ))}
            </VStack>
          </InfoWindow>
        </Show>
      </>
    );
  },
  (prevProps, nextProps) =>
    prevProps.location === nextProps.location &&
    prevProps.cars === nextProps.cars &&
    prevProps.priceReport === nextProps.priceReport &&
    prevProps.isFocused === nextProps.isFocused &&
    prevProps.isSelected === nextProps.isSelected
);
