import {isNil} from 'ramda';

import {useQueryState} from 'shared';

import {QueryStateKeys} from '../../../../../utils/routing';

export const useSelectedVehicleIdsQueryState = () => {
  const [_selectedVehicleIds, _setSelectedVehicleIds, _clearSelectedVehicleIds] = useQueryState(
    QueryStateKeys.StatisticsSelectedVehicleIds,
    true
  );
  const selectedVehicleIds = _selectedVehicleIds?.split(',') ?? null;
  const setSelectedVehicleIds = (vehicleIds: string[] | null) => {
    isNil(vehicleIds) ? _clearSelectedVehicleIds() : _setSelectedVehicleIds(vehicleIds.join(','));
  };

  return [selectedVehicleIds, setSelectedVehicleIds] as const;
};
