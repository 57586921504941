import {Availability, ExtendedStatisticsCar} from '../types';
import {isOnStock} from './isOnStock';
import {isSoldIn30Days} from './isSoldIn30Days';
import {isSoldIn90Days} from './isSoldIn90Days';

export const filterCarsByAvailability = (
  cars: ExtendedStatisticsCar[],
  visibleAvailabilities: Availability[]
): ExtendedStatisticsCar[] =>
  cars.filter(
    (car) =>
      car.isMyVehicle ||
      ((visibleAvailabilities.includes('onStock') || !isOnStock(car)) &&
        (visibleAvailabilities.includes('soldIn30Days') || !isSoldIn30Days(car)) &&
        (visibleAvailabilities.includes('soldIn90Days') || !isSoldIn90Days(car)))
  );
