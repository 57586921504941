import {Dot, Rectangle} from 'recharts';
import styled, {useTheme} from 'styled-components';

import {isNil} from 'ramda';

import {Nullish} from 'shared';

import {StatisticsChartItem} from '../types';
import {getChartItemColor} from '../utils/getChartItemColor';

interface ChartCustomBarProps {
  selectedVehicleIds: string[] | Nullish;
  activeTooltipIndex: number | Nullish;
  chartType: 'scatter' | 'bar';
  payload?: StatisticsChartItem; // is given by recharts
  index?: number; // is given by recharts
  x?: number; // is given by recharts
  y?: number; // is given by recharts
  width?: number; // is given by rechart
}

// ScatterChart has different onMouseMove prop (there is missing activeTooltipIndex attribute)
const DotWithHover = styled(Dot)`
  &:hover {
    fill: ${({theme}) => theme.colors.palettes.blue['40']['100']};
  }
`;

const MY_VEHICLE_RADIUS = 5;
const DOT_RADIUS = 8;

export function ChartCustomValueElement(props: ChartCustomBarProps) {
  const theme = useTheme();

  const isScatterChart = props.chartType === 'scatter';

  if (props.payload?.car.isMyVehicle) {
    if (isNil(props.x) || isNil(props.width) || isNil(props.y)) {
      return null;
    }

    return (
      <Dot
        cx={props.x + props.width / 2}
        cy={props.y + (isScatterChart ? MY_VEHICLE_RADIUS : 0)}
        r={MY_VEHICLE_RADIUS}
        fill="#0A2442"
        stroke="#0A2442"
        strokeOpacity={0.2}
        strokeWidth={16}
        pointerEvents="none"
      />
    );
  }

  const color = props.payload
    ? getChartItemColor(
        theme,
        props.payload?.car,
        props.activeTooltipIndex === props.payload?.index,
        !!props.selectedVehicleIds?.includes(props.payload.car.ad_id)
      )
    : undefined;

  return props.chartType === 'bar' ? (
    <Rectangle {...props} fill={color} />
  ) : (
    <DotWithHover {...props} r={DOT_RADIUS} fill={color} />
  );
}
