import {LocationWithCars} from './getLocationsWithCars';

export const getBoundsByLocations = (
  locations: LocationWithCars[]
): google.maps.LatLngBounds | null => {
  const bounds = new google.maps.LatLngBounds();
  locations.forEach(({location}) => {
    bounds.extend(location);
  });
  return bounds;
};
