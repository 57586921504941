import {getSize, Icon, Show, Space, VStack} from 'platform/foundation';
import {css, useTheme} from 'styled-components';

import {useId} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

import {FormControlProps} from '../../types/FormControlProps';
import {HelperText} from '../HelperText/HelperText';
import {IconButton} from '../IconButton/IconButton';
import {PlatformInputElement} from '../TextInput/components/PlatformInputElement';
import {useTranslationContext} from '../TranslationProvider/TranslationContext';

interface SearchProps extends TestIdProps, FormControlProps<string | null> {
  onClear?: () => void;
  highlightOnSearch?: boolean;
  isAutoFocused?: boolean;
}

export function Search(props: SearchProps) {
  const t = useTranslationContext();
  const theme = useTheme();
  const id = useId();

  const onClearClick = () => {
    props.onChange?.(null);
    props.onClear?.();
  };

  return (
    <VStack>
      <div
        css={css`
          display: flex;
          align-items: center;
          min-height: 0;
          width: 100%;
          border: 1px solid;
          height: ${getSize(8)};
          max-height: ${getSize(8)};
          border-radius: ${theme.radii.small};
          border-color: transparent;
          background: ${props.highlightOnSearch && props.value
            ? theme.colors.palettes.blue[20][100]
            : theme.colors.palettes.neutral[20][100]};
          color: ${theme.colors.text.primary};
          padding-left: ${getSize(2)};
          padding-right: ${getSize(2)};
          overflow: hidden;

          &:focus-within {
            background: ${theme.colors.palettes.white[10][100]};
            border-color: ${theme.colors.severity.informational};
            box-shadow: ${theme.shadows.active};
          }

          ${!props.isDisabled &&
          css`
            &:hover {
              border-color: ${theme.colors.severity.informational};
            }
          `}

          ${!!props.isDisabled &&
          css`
            box-shadow: none;
            cursor: not-allowed;
            opacity: 0.4;
          `}
        `}
      >
        <Icon value="action/search" size={4} />
        <Space horizontal={2} />
        <PlatformInputElement
          id={id}
          isInvalid={false}
          isDisabled={!!props.isDisabled}
          placeholder={t('general.labels.searchPlaceholder')}
          onChange={props.onChange}
          value={props.value}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          isAutoFocused={props.isAutoFocused}
          autoComplete="off"
          data-testid={suffixTestId('search', props)}
        />
        <Space horizontal={2} />
        <Show when={Boolean(props.value)}>
          <IconButton
            size="small"
            icon="navigation/close"
            onClick={onClearClick}
            data-testid={suffixTestId('searchClearButton', props)}
          />
        </Show>
        <Show when={!props.value}>
          <Space horizontal={6} />
        </Show>
      </div>
      <Show when={props.errorMessage ?? props.helperText}>
        <HelperText
          errorMessage={props.errorMessage}
          helperText={props.helperText}
          data-testid={suffixTestId('helper', props)}
        />
      </Show>
    </VStack>
  );
}
