import {BeautifulMentionsMenuItemProps} from 'lexical-beautiful-mentions';
import styled, {css} from 'styled-components';

export const StyledMenuItem = styled.li<{
  $isSelected?: boolean;
}>`
  list-style-type: none;
  padding: 8px;
  border-radius: ${({theme}) => theme.radii.small};
  font-size: ${({theme}) => theme.fontSizes.text.small};

  ${({$isSelected}) =>
    $isSelected &&
    css`
      background-color: ${({theme}) => theme.colors.palettes.blue[10][100]};
      color: ${({theme}) => theme.colors.palettes.blue[80][100]};
    `}
`;

interface CustomMenuItemProps extends BeautifulMentionsMenuItemProps {
  ref?: React.Ref<HTMLLIElement>;
}

export function CustomMenuItem({selected, ref, ...props}: CustomMenuItemProps) {
  return <StyledMenuItem {...props} ref={ref} $isSelected={selected} />;
}
