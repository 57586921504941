import {APIProvider, Map as GoogleMap, useMap} from '@vis.gl/react-google-maps';
import {DataStatus} from 'platform/components';
import {Box, Show} from 'platform/foundation';
import {useTheme} from 'styled-components';

import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {isNil, isNotNil} from 'ramda';

import {environment} from '@price-report/environment';
import {PriceReportType} from '@price-report/shared';

import {RequiredTestIdProps, suffixTestId, useBoolean} from 'shared';

import {useFilter} from '../../../../../hooks/FilterContext';
import {ExtendedStatisticsCar} from '../types';
import {getBoundsByLocations} from '../utils/getBoundsByLocations';
import {getBoundsByRadius} from '../utils/getBoundsByRadius';
import {getLocationsWithCars} from '../utils/getLocationsWithCars';
import {Circle} from './Circle';
import {MarkerWithInfo} from './MarkerWithInfo';

const DEFAULT_COORDS = {lat: 50.0755381, lng: 14.4378005};

interface MapViewProps extends RequiredTestIdProps {
  priceReport: PriceReportType;
  data: ExtendedStatisticsCar[];
  selectedVehicleIds: string[] | null;
  setSelectedVehicleIds: (ids: string[] | null) => void;
}

export function MapView(props: MapViewProps) {
  const [isLoading, , setIsLoaded] = useBoolean(true);
  return (
    <APIProvider apiKey={environment.GOOGLE_API_KEY} onLoad={setIsLoaded}>
      <MapViewComponent {...props} isLoading={isLoading} />
    </APIProvider>
  );
}

interface MapViewComponentProps extends MapViewProps {
  isLoading: boolean;
}

function MapViewComponent(props: MapViewComponentProps) {
  const {filter} = useFilter();
  const theme = useTheme();
  const map = useMap();
  const [focusedMarkerKey, setFocusedMarkerKey] = useState<string | null>(null);
  const skipFitBoundsRef = useRef(false);

  const location: google.maps.LatLngLiteral | undefined = useMemo(
    () =>
      isNotNil(filter?.location) ? {lat: filter.location.lat, lng: filter.location.lon} : undefined,
    [filter]
  );
  const radius = useMemo(
    () => (isNotNil(filter?.location) ? filter.location.distance * 1000 : undefined),
    [filter]
  );
  const locationsWithCars = useMemo(() => getLocationsWithCars(props.data), [props.data]);

  const {selectedVehicleIds, setSelectedVehicleIds} = props;
  const onSelectMarker = useCallback(
    (cars: ExtendedStatisticsCar[]) => {
      skipFitBoundsRef.current = true;

      setSelectedVehicleIds(
        cars.some((car) => selectedVehicleIds?.includes(car.ad_id))
          ? null
          : cars.map((car) => car.ad_id)
      );

      setFocusedMarkerKey(null);
    },
    [selectedVehicleIds, setSelectedVehicleIds]
  );

  useEffect(() => {
    if (skipFitBoundsRef.current) {
      skipFitBoundsRef.current = false;
      return;
    }

    if (isNotNil(map)) {
      if (isNotNil(location) && isNotNil(radius)) {
        if (radius === 0) {
          map.setCenter(location);
          map.setZoom(14); // Zoom 14 equals 1km radius
        } else {
          const bounds = getBoundsByRadius(location, radius);
          isNotNil(bounds) && map.fitBounds(bounds);
        }
      } else if (locationsWithCars.length > 0) {
        const bounds = getBoundsByLocations(locationsWithCars);
        isNotNil(bounds) && map.fitBounds(bounds);
      }
    }
  }, [locationsWithCars, location, map, radius]);

  return (
    <DataStatus isLoading={props.isLoading}>
      <Box position="absolute" height="100%" width="100%">
        <GoogleMap
          mapId="google-map-script"
          defaultZoom={isNil(location) ? 6 : 10}
          defaultCenter={location ?? DEFAULT_COORDS}
          reuseMaps
          streetViewControl={false}
        >
          {locationsWithCars.map(({key, location, cars}) => (
            <MarkerWithInfo
              key={key}
              location={location}
              cars={cars}
              priceReport={props.priceReport}
              isFocused={focusedMarkerKey === key}
              isSelected={cars.some((car) => props.selectedVehicleIds?.includes(car.ad_id))}
              onClick={onSelectMarker}
              onHover={() => setFocusedMarkerKey(key)}
              onInfoClose={() => setFocusedMarkerKey(null)}
              data-testid={suffixTestId('markerWithInfo', props)}
            />
          ))}
          <Show when={isNotNil(location) && isNotNil(radius) && radius > 0}>
            <Circle
              radius={radius}
              center={location}
              strokeColor={theme.colors.general.accent}
              fillColor={theme.colors.general.accent}
              fillOpacity={0.1}
            />
          </Show>
        </GoogleMap>
      </Box>
    </DataStatus>
  );
}
