import {useFloatingTree, useListItem} from '@floating-ui/react';
import {
  getColorFromSeverity,
  Icon,
  Severity,
  Show,
  Spinner,
  Text,
  TextProps,
  ThemeIconKey,
} from 'platform/foundation';
import styled from 'styled-components';
import {match, Pattern} from 'ts-pattern';

import {MouseEventHandler, ReactElement, ReactNode, useContext} from 'react';

import {always} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

import {MenuContext} from './DropdownContainer';

export interface DropdownItemProps extends TestIdProps {
  label: string | ReactElement<TextProps, typeof Text>;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  isDisabled?: boolean;
  prefix?: ReactNode;
  suffix?: ReactElement;
  leftIcon?: ThemeIconKey;
  isLoading?: boolean;
  severity?: Severity;
  isActive?: boolean;
}

export function DropdownItem(props: DropdownItemProps) {
  const menu = useContext(MenuContext);
  const item = useListItem({label: props.label as string});
  const tree = useFloatingTree();
  const isActive = item.index === menu.activeIndex;
  const iconColor = props.severity ? getColorFromSeverity(props.severity) : undefined;

  const icon = props.leftIcon && (
    <Show when={props.leftIcon}>
      <Icon color={iconColor} value={props.leftIcon} size={4} />
    </Show>
  );

  const content = (
    <>
      {props.label}
      {props.isLoading && <Spinner variant="overlay" size="small" />}
    </>
  );

  const shouldWrap = icon || props.suffix;

  const children = shouldWrap ? (
    <span style={{pointerEvents: 'none', flex: 1}}>{content}</span>
  ) : (
    content
  );

  return (
    <StyledMenuItem
      ref={item.ref}
      tabIndex={isActive ? 0 : -1}
      isActive={isActive}
      type="button"
      role="menuitem"
      {...menu.getItemProps({
        onClick(event: React.MouseEvent<HTMLButtonElement>) {
          props.onClick?.(event);
          tree?.events.emit('click');
          menu.setOnItemClick();
        },
      })}
      data-testid={suffixTestId('dropdownItem', props)}
    >
      <StyledMenuIcon>
        {icon ? icon : (isNotNil(props.prefix) && <>{props.prefix}</>) || undefined}
      </StyledMenuIcon>
      {children}
      {props.suffix && <StyledMenuCommand>{props.suffix}</StyledMenuCommand>}
    </StyledMenuItem>
  );
}

const StyledMenuIcon = styled.span`
  flex-shrink: 0;
  margin-inline-end: 12px;
  font-size: 0.8rem;

  svg {
    height: 16px;
    width: 16px;
    &[data-iconkey^='flags/'] {
      width: 28px;
      height: 20px;
    }
  }
`;

const StyledMenuCommand = styled.span`
  opacity: 1;
  color: ${({theme}) => theme.colors.palettes.blue[80][100]};
  margin-inline-start: 0.75rem;

  svg {
    height: 16px;
    width: 16px;
    &[data-iconkey^='flags/'] {
      width: 28px;
      height: 20px;
    }
  }
`;

type StyledMenuItemProps = {
  isDisabled?: DropdownItemProps['isDisabled'];
  isActive?: DropdownItemProps['isActive'];
};

const StyledMenuItem = styled.button<StyledMenuItemProps>`
  text-decoration: none;
  user-select: none;
  display: flex;
  width: 100%;
  align-items: center;
  text-align: start;
  flex: auto;
  //justify-content: space-between;
  outline: 0;
  max-height: 36px;
  padding: 8px;
  font-size: ${({theme}) => theme.fontSizes.text.small};
  font-weight: ${({theme}) => theme.fontWeights.text.default};
  line-height: ${({theme}) => theme.lineHeights.text.small};
  border-radius: ${({theme}) => theme.radii.medium};
  letter-spacing: 0;
  color: ${({isDisabled, isActive, theme}) =>
    match([isDisabled, isActive])
      .with([true, Pattern.any], always(theme.colors.palettes.neutral[900][40]))
      .with([Pattern.any, true], always(theme.colors.palettes.blue[80][100]))
      .otherwise(always(theme.colors.text.primary))};

  &:hover {
    background-color: ${({theme, isDisabled}) =>
      isDisabled ? theme.colors.palettes.white[10][100] : theme.colors.palettes.blue[10][100]};
    color: ${({theme, isDisabled}) =>
      isDisabled ? theme.colors.palettes.neutral[900][40] : theme.colors.palettes.blue[80][100]};
  }

  &:active {
    background-color: ${({theme, isDisabled}) =>
      isDisabled ? theme.colors.palettes.white[10][100] : theme.colors.palettes.blue[10][100]};
    color: ${({theme, isDisabled}) =>
      isDisabled ? theme.colors.palettes.neutral[900][40] : theme.colors.palettes.blue[80][100]};
  }

  &:focus {
    background-color: ${({theme}) => theme.colors.palettes.blue[10][100]};
    color: ${({theme, isDisabled}) =>
      isDisabled ? theme.colors.palettes.neutral[900][40] : theme.colors.palettes.blue[80][100]};
  }
`;
