import styled from 'styled-components';

import {suffixTestId, TestIdProps} from 'shared';

export interface DropdownDividerProps extends TestIdProps {}

export function DropdownDivider(props: DropdownDividerProps) {
  return <StyledDivider data-testid={suffixTestId('dropdownDivider', props)} />;
}

const StyledDivider = styled.hr`
  margin-top: 8px;
  margin-bottom: 8px;
  color: ${({theme}) => theme.colors.general.separator};
`;
