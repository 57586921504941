import {useElementSize, Show, Space, VStack} from 'platform/foundation';
import {useFormatNumber} from 'platform/locale';
import {css} from 'styled-components';

import {FocusEvent, useId, useState} from 'react';

import {isNotNaN, isNotNil, isNumber} from 'ramda-adjunct';

import {Nullish, suffixTestId, TestIdProps, useBoolean} from 'shared';

import {FormControlProps} from '../../types/FormControlProps';
import {DropdownItem} from '../Dropdown/components/DropdownItem';
import {Dropdown} from '../Dropdown/Dropdown';
import {HelperText} from '../HelperText/HelperText';
import {IconButton} from '../IconButton/IconButton';
import {Label} from '../Label/Label';
import {PlatformNumberInputElement} from '../NumberInput/components/PlatformNumberInputElement';
import {PlatformInputWrapper} from '../TextInput/components/PlatformInputWrapper';

export interface NumberInputWithOptionsProps
  extends FormControlProps<number | null, HTMLInputElement, [string | null]>,
    TestIdProps {
  min?: number;
  max?: number;
  /**
   * @about
   * The number of allowed decimal places
   */
  decimalPlaces?: number;
  options: number[];
  openOnFocus?: boolean;
  placeholder?: string;
  isRecommended?: boolean;
  isClearable?: boolean;
  isFormatNumbersDisabled?: boolean;
}

export function NumberInputWithOptions(props: NumberInputWithOptionsProps) {
  const formatNumber = useFormatNumber();

  const id = useId();

  const [inputValue, setInputValue] = useState<string | Nullish>();
  const [isOpen, open, close] = useBoolean(false);
  const [isFocused, setFocusedTrue, setFocusedFalse] = useBoolean(false);

  const [ref, width] = useElementSize<HTMLDivElement>();

  const handleOptionSelect = (value: number) => props.onChange?.(value, undefined, String(value));

  const handleInputFocus = (event: FocusEvent<HTMLInputElement, Element>) => {
    setFocusedTrue();
    props.onFocus?.(event);
  };
  const handleInputBlur = (event: FocusEvent<HTMLInputElement, Element>) => {
    setFocusedFalse();
    props.onBlur?.(event);
  };

  const isInvalid = props.isInvalid || !!props.errorMessage;

  const isDropdownOpen = props.openOnFocus ? isFocused : isOpen;

  return (
    <VStack>
      <Label
        id={id}
        isRequired={props.isRequired}
        isRecommended={props.isRecommended}
        data-testid={suffixTestId('label', props)}
      >
        {props.label}
      </Label>
      <Dropdown
        isOpen={props.isDisabled ? false : isDropdownOpen}
        onClose={close}
        placement="bottom"
        isHeightLimited
        isFocusManagementDisabled
        dropdownControl={
          <PlatformInputWrapper
            isDisabled={props.isDisabled}
            isInvalid={isInvalid}
            ref={ref}
            isRecommended={props.isRecommended}
          >
            <PlatformNumberInputElement
              errorMessage={props.errorMessage}
              helperText={props.helperText}
              isDisabled={props.isDisabled}
              isRequired={props.isRequired}
              label={props.label}
              max={props.max}
              min={props.min}
              name={props.name}
              onChange={props.onChange}
              placeholder={props.placeholder}
              tooltip={props.tooltip}
              value={props.value}
              decimalPlaces={props.decimalPlaces}
              id={id}
              isInvalid={isInvalid}
              inputValue={inputValue}
              onBlur={handleInputBlur}
              onFocus={handleInputFocus}
              setInputValue={setInputValue}
              isFormatNumbersDisabled={props.isFormatNumbersDisabled}
              data-testid={props['data-testid']}
            />
            <Space horizontal={2} />
            <Show when={props.isClearable && isNotNil(props.value) && isNotNaN(props.value)}>
              <IconButton
                onClick={() => props.onChange?.(null, undefined, null)}
                size="small"
                icon="navigation/close"
                isDisabled={props.isDisabled}
                data-testid={suffixTestId('clearableButton', props)}
              />
            </Show>
            <IconButton
              onClick={() => (isOpen ? close() : open())}
              size="small"
              icon={isOpen ? 'navigation/expand_less' : 'navigation/expand_more'}
              isDisabled={props.isDisabled}
              data-testid={suffixTestId('expandButton', props)}
            />
          </PlatformInputWrapper>
        }
        data-testid={suffixTestId('dropdown', props)}
      >
        <div
          css={css`
            min-width: ${isNumber(width) ? `${width}px` : 'initial'};
          `}
        >
          {props.options.map((option, index) => (
            <DropdownItem
              key={option}
              label={
                (props.isFormatNumbersDisabled ? option?.toString() : formatNumber(option)) ?? ''
              }
              onClick={() => handleOptionSelect(option)}
              data-testid={suffixTestId(`option-${index}`, props)}
            />
          ))}
        </div>
      </Dropdown>
      <Show when={props.errorMessage ?? props.helperText}>
        <HelperText
          errorMessage={props.errorMessage}
          helperText={props.helperText}
          data-testid={suffixTestId('helper', props)}
        />
      </Show>
    </VStack>
  );
}
