import {Option} from 'platform/components';
import {match} from 'ts-pattern';

import {useCallback, useMemo} from 'react';

import {FALLBACK_LANGUAGE, GetAllEnumsResponse, i18n} from '@price-report/shared';

import {useGetAllEnumsQuery} from '../store/catalogueApi';
import {usePriceReport} from './usePriceReport';

type CatalogObject = {
  [vehicleType: string]: {
    [propertyName: string]: GetAllEnumsResponse[0]['keys'];
  };
};

export const sortData = (data?: GetAllEnumsResponse) => {
  const sortedData: CatalogObject = {};

  data?.forEach((item) => {
    if (item.vehicle_type) {
      if (item.vehicle_type in sortedData) {
        sortedData[item.vehicle_type] = {
          ...sortedData[item.vehicle_type],
          [item.property_name]: item.keys,
        };
      } else {
        sortedData[item.vehicle_type] = {[item.property_name]: item.keys};
      }
    }
  });

  return sortedData;
};

export const getOptionsByVehicleTypeAndProperty = (
  sortedData: CatalogObject,
  vehicleType: string,
  property: string
) => {
  const vehicleTypeData = sortedData?.[vehicleType];
  const items = vehicleTypeData?.[property];

  if (!items) {
    return null;
  }

  return items.map((item) => ({
    label: item.labels[0]?.label ?? item.const_key,
    value: item.const_key,
  }));
};

export const getPropertyLabelByVehicleTypeAndProperty = (
  sortedData: CatalogObject,
  vehicleType: string,
  property: string,
  key?: string | null
) => {
  if (!key) {
    return '';
  }

  return (
    sortedData?.[vehicleType]?.[property]?.find((e) => e.const_key === key)?.labels[0]?.label ?? key
  );
};

// Data sent by Alpha are sometimes inconsistent – body style has value for incorrect vehicle type
// i.e. body style VANSTYLE_VAN exists for vehicle type VEHICLETYPE_VAN,
//  but the actual vehicle type is VEHICLETYPE_PASSENGER_CAR
export const deriveVehicleTypeFromCarStyle = (carStyle?: string | null) => {
  if (!carStyle) {
    return carStyle;
  }

  const carStyleBase = carStyle?.split('_')[0];
  const vehicleTypeBase = carStyleBase?.substring(0, carStyleBase.length - 'STYLE'.length);
  return match(vehicleTypeBase)
    .with('CAR', () => 'VEHICLETYPE_PASSENGER_CAR')
    .otherwise(() => `VEHICLETYPE_${vehicleTypeBase}`);
};

export type CatalogueType = {
  isError: boolean;
  isLoading: boolean;
  getCarStyle: (carStyle?: string | null, deriveVehicleType?: boolean) => string;
  getFuelType: (fuelType?: string | null) => string;
  getDrive: (drive?: string | null) => string;
  getFeatureLabel: (feature?: string | null) => string;
  hasFeatureTranslation: (feature?: string | null) => boolean;
  getFeaturePriority: (feature?: string | null) => number;
  getTransmission: (transmission?: string | null) => string;
  getColorType: (colorType?: string | null) => string;
  getBodyColor: (bodyColor?: string | null) => string;
  getBodyColorHEX: (bodyColor?: string | null) => string | undefined;
  getInteriorMaterial: (interiorMaterial?: string | null) => string;
  getEmissionClass: (emissionClass?: string | null) => string;
  getDoorCount: (doorCount?: string | null) => string;
  driveOptions: Option[] | null;
  fuelOptions: Option[] | null;
  styleOptions: Option[] | null;
  transmissionOptions: Option[] | null;
  features: CatalogObject[string][string];
  colorOptions: Option[] | null;
  doorOptions: Option[] | null;
  interiorMaterialOptions: Option[] | null;
};

const VEHICLETYPE_PASSENGER_CAR = 'VEHICLETYPE_PASSENGER_CAR';

export function useCatalogue(): CatalogueType {
  const {priceReport} = usePriceReport();
  const {data, isError, isLoading} = useGetAllEnumsQuery({
    lang: [i18n?.resolvedLanguage ?? FALLBACK_LANGUAGE],
  });

  const vehicleType = useMemo(
    () => priceReport?.vehicle.vehicleType ?? VEHICLETYPE_PASSENGER_CAR,
    [priceReport]
  );

  const sortedData = useMemo(() => sortData(data), [data]);

  const getOptions = useCallback(
    (property: string) => getOptionsByVehicleTypeAndProperty(sortedData, vehicleType, property),
    [sortedData, vehicleType]
  );

  const driveOptions = useMemo<Option[] | null>(
    () =>
      (getOptions('drive') ?? []).filter(
        (driveOption) => driveOption.value !== 'DRIVE_REAR' && driveOption.value !== 'DRIVE_FRONT'
      ),
    [getOptions]
  );
  const fuelOptions = useMemo<Option[] | null>(() => {
    const options = getOptions('fuel_type');

    return (
      options?.reduce((mem: Option[], option) => {
        if (option.value === 'FUELTYPE_PETROL' || option.value === 'FUELTYPE_DIESEL') {
          return [option, ...mem];
        }
        return [...mem, option];
      }, []) ?? null
    );
  }, [getOptions]);
  const styleOptions = useMemo<Option[] | null>(
    () =>
      match(vehicleType)
        .with('VEHICLETYPE_PASSENGER_CAR', () => getOptions('car_style'))
        .with('VEHICLETYPE_BUS', () => getOptions('bus_style'))
        .with('VEHICLETYPE_CARAVAN', () => getOptions('caravan_style'))
        .with('VEHICLETYPE_MOTORCYCLE', () => getOptions('motorcycle_style'))
        .with('VEHICLETYPE_TRUCK', () => getOptions('truck_style'))
        .with('VEHICLETYPE_VAN', () => getOptions('van_style'))
        .otherwise(() => getOptions('car_style')),
    [getOptions, vehicleType]
  );
  const transmissionOptions = useMemo<Option[] | null>(
    () => getOptions('transmission'),
    [getOptions]
  );
  const colorOptions = useMemo<Option[] | null>(() => getOptions('body_color'), [getOptions]);
  const doorOptions = useMemo<Option[] | null>(
    () => getOptions('door_count')?.filter((option) => option.label.includes('/')) ?? null,
    [getOptions]
  );
  const interiorMaterialOptions = useMemo<Option[] | null>(
    () => getOptions('interior_material') ?? null,
    [getOptions]
  );
  const features = useMemo(() => sortedData?.VEHICLETYPE_PASSENGER_CAR?.feature, [sortedData]);

  const getPropertyLabel = useCallback(
    (property: string, key?: string | null, customVehicleType?: string | null) =>
      getPropertyLabelByVehicleTypeAndProperty(
        sortedData,
        customVehicleType ?? vehicleType,
        property,
        key
      ),
    [sortedData, vehicleType]
  );

  const getBodyColor = useCallback(
    (bodyColor?: string | null) => getPropertyLabel('body_color', bodyColor),
    [getPropertyLabel]
  );

  const getBodyColorHEX = useCallback(
    (bodyColor?: string | null) => {
      const bodyColors = data?.find((item) => item.property_name === 'body_color')?.keys ?? [];
      const bodyColorObject = bodyColors.find((key) => key.const_key === bodyColor);

      return bodyColorObject?.properties?.find((property) => property.name === 'color_code')?.value;
    },
    [data]
  );

  const getColorType = useCallback(
    (colorType?: string | null) => getPropertyLabel('color_type', colorType),
    [getPropertyLabel]
  );

  const getInteriorMaterial = useCallback(
    (interiorMaterial?: string | null) => getPropertyLabel('interior_material', interiorMaterial),
    [getPropertyLabel]
  );

  const getEmissionClass = useCallback(
    (emissionClass?: string | null) => getPropertyLabel('emission_class', emissionClass),
    [getPropertyLabel]
  );

  const getDoorCount = useCallback(
    (doorCount?: string | null) => getPropertyLabel('door_count', doorCount),
    [getPropertyLabel]
  );

  const getFuelType = useCallback(
    (fuelType?: string | null) => getPropertyLabel('fuel_type', fuelType),
    [getPropertyLabel]
  );

  const getCarStyle = useCallback(
    (carStyle?: string | null) => {
      const property = match(deriveVehicleTypeFromCarStyle(carStyle))
        .with('VEHICLETYPE_PASSENGER_CAR', () => 'car_style')
        .with('VEHICLETYPE_BUS', () => 'bus_style')
        .with('VEHICLETYPE_CARAVAN', () => 'caravan_style')
        .with('VEHICLETYPE_MOTORCYCLE', () => 'motorcycle_style')
        .with('VEHICLETYPE_TRUCK', () => 'truck_style')
        .with('VEHICLETYPE_VAN', () => 'van_style')
        .otherwise(() => 'car_style');

      return getPropertyLabel(property, carStyle, deriveVehicleTypeFromCarStyle(carStyle));
    },
    [getPropertyLabel]
  );

  const getDrive = useCallback(
    (drive?: string | null) => getPropertyLabel('drive', drive),
    [getPropertyLabel]
  );

  // Alpha return interior material as a feature
  const getFeatureLabel = useCallback(
    (feature?: string | null) =>
      feature?.startsWith('INTERIORMATERIAL_')
        ? getPropertyLabel('interior_material', feature)
        : getPropertyLabel('feature', feature),
    [getPropertyLabel]
  );

  // Alpha doesn't provide translation for some features
  const hasFeatureTranslation = useCallback(
    (feature?: string | null) => getFeatureLabel(feature) !== feature,
    [getFeatureLabel]
  );

  const getFeaturePriority = useCallback(
    (feature?: string | null) =>
      sortedData?.[vehicleType]?.feature?.find((e) => e.const_key === feature)?.sort_priority ?? 0,
    [sortedData, vehicleType]
  );

  const getTransmission = useCallback(
    (transmission?: string | null) => getPropertyLabel('transmission', transmission),
    [getPropertyLabel]
  );

  return useMemo(
    () => ({
      isError,
      isLoading,
      getCarStyle,
      getFuelType,
      getDrive,
      getFeatureLabel,
      hasFeatureTranslation,
      getFeaturePriority,
      getTransmission,
      getColorType,
      getBodyColor,
      getBodyColorHEX,
      getInteriorMaterial,
      getEmissionClass,
      getDoorCount,
      driveOptions,
      fuelOptions,
      styleOptions,
      transmissionOptions,
      features,
      colorOptions,
      doorOptions,
      interiorMaterialOptions,
    }),
    [
      isError,
      isLoading,
      getCarStyle,
      getFuelType,
      getDrive,
      getFeatureLabel,
      hasFeatureTranslation,
      getFeaturePriority,
      getTransmission,
      getColorType,
      getBodyColor,
      getBodyColorHEX,
      getInteriorMaterial,
      getEmissionClass,
      getDoorCount,
      driveOptions,
      fuelOptions,
      styleOptions,
      transmissionOptions,
      features,
      colorOptions,
      doorOptions,
      interiorMaterialOptions,
    ]
  );
}
