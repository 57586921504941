import {createContext, useState, use, ReactNode} from 'react';

import {FiltersErrors, FiltersRequestBody} from '@price-report/shared';

import {noop} from 'shared';

type FilterContextType = {
  filter: FiltersRequestBody | null;
  onFilterChange: (data: FiltersRequestBody) => void;
  errors: FiltersErrors | null;
  setErrors: (errors: FiltersErrors | null) => void;
  isOpenMobileFilter: boolean;
  openMobileFilter: () => void;
  closeMobileFilter: () => void;
};

const FilterContext = createContext<FilterContextType>({
  filter: null,
  onFilterChange: noop,
  errors: null,
  setErrors: noop,
  isOpenMobileFilter: false,
  openMobileFilter: noop,
  closeMobileFilter: noop(),
});

interface FilterProviderProps {
  children: (context: FilterContextType) => ReactNode;
  initialFilter: FiltersRequestBody;
}

function FilterProvider(props: FilterProviderProps) {
  const [filter, setFilter] = useState<FiltersRequestBody>(props.initialFilter);
  const [errors, setErrors] = useState<FiltersErrors | null>(null);
  const [isOpenMobileFilter, setIsOpenMobileFilter] = useState(false);

  const context: FilterContextType = {
    filter,
    onFilterChange: setFilter,
    errors,
    setErrors,
    isOpenMobileFilter,
    openMobileFilter: () => setIsOpenMobileFilter(true),
    closeMobileFilter: () => setIsOpenMobileFilter(false),
  };

  return <FilterContext.Provider value={context}>{props.children(context)}</FilterContext.Provider>;
}

const useFilter = () => use(FilterContext);

export {FilterProvider, useFilter, FilterContextType};
