import {getValueByDevice, useDevice} from 'platform/foundation';
import {currencies, formatCurrencySymbol, useFormatPercentage} from 'platform/locale';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  YAxisProps,
} from 'recharts';
import {CategoricalChartFunc} from 'recharts/types/chart/generateCategoricalChart';
import {useTheme} from 'styled-components';
import {match} from 'ts-pattern';

import {useMemo, useState} from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';

import {isNil, length} from 'ramda';
import {isNilOrEmpty, isNotNil, isNumber} from 'ramda-adjunct';

import {i18n, PriceReportType} from '@price-report/shared';

import {CurrencyCodeType, RequiredTestIdProps, suffixTestId, useQueryState} from 'shared';

import {useGetPriceReportCarsDetailQuery} from '../../../../../store/priceReportApi';
import {isInCypress} from '../../../../../utils/isInCypress';
import {QueryStateKeys} from '../../../../../utils/routing';
import {useXAxisTickFormatter} from '../hooks/useXAxisTickFormatter';
import {
  Formatter,
  useYAxisTickFormatter,
  useYAxisTickFormatterWithUnitOnMobile,
} from '../hooks/useYAxisTickFormatter';
import {renderReferenceLineX} from '../renders/renderReferenceLineX';
import {renderReferenceLineY} from '../renders/renderReferenceLineY';
import {ExtendedStatisticsCar, StatisticsChartItem} from '../types';
import {ChartVariant} from '../types/ChartVariant';
import {getAverageValueWithoutMyVehicle} from '../utils/getAverageValueWithoutMyVehicle';
import {getChartCoordinates} from '../utils/getChartCoordinates';
import {getXAxisLabel} from '../utils/getXAxisLabel';
import {getYAxisLabel} from '../utils/getYAxisLabel';
import {mapAvailableCarToExtendedStatisticCar} from '../utils/mapAvailableCarToExtendedStatisticCar';
import {ChartCustomValueElement} from './ChartCustomValueElement';
import {ChartTooltip} from './ChartTooltip';

const AXIS_OFFSET = 100;
const ROUND_TO_VALUE = 100;
const axisDomain: YAxisProps['domain'] = [
  (minValue: number) =>
    Math.max(0, Math.floor((minValue - AXIS_OFFSET) / ROUND_TO_VALUE) * ROUND_TO_VALUE),
  'auto',
];

interface ChartViewProps extends RequiredTestIdProps {
  chartVariant: ChartVariant;
  priceReport: PriceReportType;
  data: ExtendedStatisticsCar[];
  selectedVehicleIds: string[] | null;
  setSelectedVehicleIds: (ids: string[] | null) => void;
}

export function ChartView(props: ChartViewProps) {
  const theme = useTheme();
  const formatPercentage = useFormatPercentage();
  const device = useDevice();
  const [activeTooltipIndex, setActiveTooltipIndex] = useState<number | null>(null);
  const [debug] = useQueryState(QueryStateKeys.Debug);

  const {data: neighboursData} = useGetPriceReportCarsDetailQuery(
    {reportId: props.priceReport.id, vehicleIds: props.priceReport.neighborAdIds ?? []},
    {skip: debug !== 'true' || isNilOrEmpty(props.priceReport.neighborAdIds)}
  );

  const isScatterChart = props.chartVariant === 'priceMap';

  const onMouseMoveBarChart: CategoricalChartFunc = (state) => {
    if (isNil(state)) {
      return;
    }
    if (!state.isTooltipActive) {
      setActiveTooltipIndex(null);
    }
    if (state.activePayload?.[0]?.payload?.isMyVehicle) {
      return;
    }
    if (isNumber(state.activeTooltipIndex)) {
      setActiveTooltipIndex(state.activeTooltipIndex);
    }
  };

  const onClick = (item: StatisticsChartItem) =>
    props.setSelectedVehicleIds(
      props.selectedVehicleIds?.includes(item.car.ad_id) ? null : [item.car.ad_id]
    );

  const currencyCode = props.priceReport.sellingPrice.recommended.currency as CurrencyCodeType;
  const currencySymbol = formatCurrencySymbol(currencies, currencyCode);

  const xAxisTickFormatter = useXAxisTickFormatter(props.chartVariant);
  const yAxisTickFormatter = useYAxisTickFormatter(props.chartVariant);
  const yAxisTickFormatterWithUnitOnMobile = useYAxisTickFormatterWithUnitOnMobile(
    props.chartVariant
  );

  const neighbours = useMemo(() => {
    if (props.chartVariant !== 'priceMap' || isNil(neighboursData)) {
      return [];
    }

    return neighboursData.map(mapAvailableCarToExtendedStatisticCar);
  }, [neighboursData, props.chartVariant]);

  // mapped data must be memoized - when mapped data is changed, recharts reset current zoom to default
  const mappedData: StatisticsChartItem[] = useMemo(() => {
    const merged = [...props.data, ...neighbours];

    return merged.map((car, index) => ({
      index,
      ...getChartCoordinates(car, props.chartVariant),
      car,
    }));
  }, [props.data, neighbours, props.chartVariant]);

  const averageValueX = isScatterChart ? getAverageValueWithoutMyVehicle('x', mappedData) : null;
  const averageValueY = getAverageValueWithoutMyVehicle('y', mappedData);

  const myVehicleItem = mappedData.find((item) => item.car.isMyVehicle);

  const Chart = isScatterChart ? ScatterChart : BarChart;
  const isAnimationActive = !isInCypress;

  const yReferenceLineLabelFormatter: Formatter = (value) =>
    match<ChartVariant, string>(props.chartVariant)
      .with('price', () =>
        i18n.t('entity.statisticsChart.labels.thousandsCurrency', {
          value: yAxisTickFormatter(value),
          currencySymbol,
        })
      )
      .with('priceMap', () =>
        i18n.t('entity.statisticsChart.labels.thousandsCurrency', {
          value: yAxisTickFormatter(value),
          currencySymbol,
        })
      )
      .with('mileage', () =>
        i18n.t('entity.statisticsChart.labels.thousandsKm', {
          value: yAxisTickFormatter(value),
        })
      )
      .with('featuresLevel', () => formatPercentage(value / 100, 'down'))
      .otherwise(() => yAxisTickFormatter(value));

  return (
    <AutoSizer>
      {({width, height}) => (
        <Chart
          data={mappedData}
          onMouseMove={!isScatterChart ? onMouseMoveBarChart : undefined}
          width={width}
          height={height}
        >
          <CartesianGrid stroke={theme.colors.palettes.neutral['30'][100]} />
          {isScatterChart ? (
            <Scatter
              data={mappedData}
              onClick={onClick}
              shape={
                <ChartCustomValueElement
                  selectedVehicleIds={props.selectedVehicleIds}
                  activeTooltipIndex={activeTooltipIndex}
                  chartType="scatter"
                />
              }
              isAnimationActive={isAnimationActive}
            />
          ) : (
            <Bar
              dataKey="y"
              minPointSize={5}
              radius={[4, 4, 0, 0]}
              onClick={onClick}
              shape={
                <ChartCustomValueElement
                  selectedVehicleIds={props.selectedVehicleIds}
                  activeTooltipIndex={activeTooltipIndex}
                  chartType="bar"
                />
              }
              isAnimationActive={isAnimationActive}
            />
          )}
          <YAxis
            dataKey="y"
            type="number"
            tickFormatter={yAxisTickFormatterWithUnitOnMobile}
            tickLine={false}
            fontSize={12}
            axisLine={false}
            width={getValueByDevice(device, 48, 72, 72, 72)}
            padding={{top: 40}}
            domain={axisDomain}
          >
            <Label
              value={getYAxisLabel(props.chartVariant, currencyCode, device)}
              angle={-90}
              position="insideLeft"
              fontSize={14}
            />
          </YAxis>
          <XAxis
            dataKey="x"
            type={isScatterChart ? 'number' : 'category'}
            scale={isScatterChart ? 'auto' : 'band'}
            axisLine={false}
            tickFormatter={xAxisTickFormatter}
            tickLine={false}
            fontSize={12}
            height={48}
            domain={isScatterChart ? axisDomain : undefined}
            padding={isScatterChart ? {right: 40} : undefined}
          >
            <Label
              value={getXAxisLabel(props.chartVariant)}
              position="insideBottom"
              fontSize={14}
            />
          </XAxis>
          {device !== 'mobile' && (
            <Tooltip
              content={
                <ChartTooltip
                  priceReport={props.priceReport}
                  data-testid={suffixTestId('chartTooltip', props)}
                />
              }
              wrapperStyle={{outline: 'none'}}
              cursor={false}
            />
          )}
          {myVehicleItem && (
            <>
              {renderReferenceLineX({
                value: myVehicleItem.x,
                formatter: xAxisTickFormatter,
                flagColor: 'black',
                flagVerticalAlign: 'bottom',
                flagHorizontalAlign: 'left',
                lineColor: '#0A2442',
                isLabelVisible: props.chartVariant === 'priceMap',
              })}
              {renderReferenceLineY({
                value: myVehicleItem.y,
                formatter: yReferenceLineLabelFormatter,
                flagColor: 'black',
                flagVerticalAlign: 'bottom',
                lineColor: '#0A2442',
              })}
            </>
          )}
          {length(mappedData) > 1 && (
            <>
              {renderReferenceLineY({
                value: averageValueY,
                formatter: (value) => `Ø ${yReferenceLineLabelFormatter(value)}`,
                lineColor: theme.colors.palettes.neutral[60][100],
                flagColor: 'neutral',
                flagVerticalAlign: 'top',
                isFlagSubtle: true,
                flagHorizontalAlign: 'right',
                isDashed: true,
              })}
              {isNotNil(averageValueX) &&
                renderReferenceLineX({
                  value: averageValueX,
                  formatter: (value) => `Ø ${xAxisTickFormatter(value)}`,
                  lineColor: theme.colors.palettes.neutral[60][100],
                  flagColor: 'neutral',
                  flagHorizontalAlign: 'right',
                  isFlagSubtle: true,
                  isDashed: true,
                  isLabelVisible: props.chartVariant === 'priceMap',
                })}
            </>
          )}
        </Chart>
      )}
    </AutoSizer>
  );
}
