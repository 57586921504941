import {useDevice} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useMatch, useParams} from 'react-router-dom';

import {isNotNil} from 'ramda';

import {useNavigate} from 'shared';

import {Direction} from '../pages/AdvertisementPage/types/direction';
import {getDefaultDirection} from '../pages/AdvertisementPage/utils/getDefaultDirection';
import {defaultChartType} from '../pages/StatisticsPage/components/Chart/consts/defaultChartType';
import {ChartVariant} from '../pages/StatisticsPage/components/Chart/types/ChartVariant';
import {
  advertisementPath,
  getAdvertisementPath,
  getStatisticsPath,
  statisticsPath,
} from '../utils/routing';

export const usePriceReportSearchParams = () => {
  const navigate = useNavigate();
  const params = useParams();
  const device = useDevice();
  const isStatisticsPage = !!useMatch(statisticsPath);
  const isAdvertisementPage = !!useMatch(advertisementPath);

  // Statistics page
  const chartVariant = match<string | undefined, ChartVariant>(params.chartType)
    .with('price', () => 'price')
    .with('mileage', () => 'mileage')
    .with('daysOnStock', () => 'daysOnStock')
    .with('featuresLevel', () => 'featuresLevel')
    .with('priceMap', () => 'priceMap')
    .with('mapView', () => 'mapView')
    .otherwise(() => defaultChartType);
  const setChartVariant = (chartVariant: ChartVariant) => {
    if (isStatisticsPage) {
      navigate(getStatisticsPath({...params, chartType: chartVariant}, true));
    }
  };

  // Advertisement page
  const direction = match<string | undefined, Direction>(params.view)
    .with('row', () => 'row')
    .with('column', () => 'column')
    .otherwise(() => getDefaultDirection(device));
  const setDirection = (direction: Direction) => {
    if (isAdvertisementPage) {
      navigate(getAdvertisementPath({...params, view: direction}, true));
    }
  };

  // For advertisement page we do not know default page before the data comes
  const defaultPage = isAdvertisementPage ? null : 1;

  // Both statistics and advertisement page
  const page = isNotNil(params.page) ? Number(params.page) : defaultPage;
  const setPage = (page: number | null) => {
    if (isStatisticsPage) {
      navigate(
        getStatisticsPath(
          {
            ...params,
            chartType: params.chartType ?? defaultChartType,
            page: (page ?? defaultPage)?.toString(),
          },
          true
        )
      );
    }
    if (isAdvertisementPage) {
      navigate(
        getAdvertisementPath(
          {
            ...params,
            view: direction,
            page: (page ?? defaultPage)?.toString(),
          },
          true
        )
      );
    }
  };
  const resetPage = () => {
    if (page !== defaultPage) {
      setPage(defaultPage);
    }
  };

  return {chartVariant, setChartVariant, direction, setDirection, page, setPage, resetPage};
};
