import {yupResolver} from '@hookform/resolvers/yup';
import {zodResolver} from '@hookform/resolvers/zod';
import * as Yup from 'yup';
import {AnyZodObject} from 'zod';

import {ReactNode, useCallback, useId, useMemo} from 'react';
import {
  DefaultValues,
  FieldValues,
  FormProvider,
  Path,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  UseFormReset,
  UseFormReturn,
  ValidationMode,
} from 'react-hook-form';

import {environment} from '@dms/environment';

import {Nullish, OptionsType} from 'shared';

import {UnsavedChangesNotification} from './components/UnsavedChangesNotification';
import {useUnsavedChangesNotification} from './hooks/useUnsavedChangesNotification';
import {FormControl, FormSubmitHandler, ValidationErrors} from './types';

export interface FormProps<TFieldValues extends FieldValues = FieldValues> {
  /**
   * @example
   * <Form>
   *  {(control) => (
   *    <FormField control={control} name="title" type="text" />
   *  )}
   * </Form>
   */
  children: (
    control: FormControl<TFieldValues>,
    formApi: UseFormReturn<TFieldValues>,
    navigateWithoutBlocker: <Path extends string>(
      path: Path,
      options?: OptionsType<Path> | undefined
    ) => void
  ) => ReactNode;
  defaultValues?: DefaultValues<TFieldValues> | Nullish;
  values?: TFieldValues | Nullish;
  schema?: Yup.AnyObjectSchema;
  mode?: keyof ValidationMode;
  /**
   * @about onSubmit function has to be async and typed as FormSubmitHandler<YourFormValues>
   * @example
   * const onSubmit: FormSubmitHandler<LoginFormValues> = async (values) => {
   *   await loginToApp(...)
   * };
   */
  onSubmit?: FormSubmitHandler<TFieldValues>;
  onInvalidSubmit?: SubmitErrorHandler<TFieldValues>;
  onChange?: (
    values: TFieldValues,
    setErrors: (errors: ValidationErrors | null) => void,
    reset: UseFormReset<TFieldValues>,
    formApi: UseFormReturn<TFieldValues>
  ) => void;
  shouldWatchForUnsavedChanges?: boolean;
  isFullHeight?: boolean;
  experimentalZodSchema?: AnyZodObject;
}

export function Form<TFieldValues extends FieldValues = FieldValues>(
  props: FormProps<TFieldValues>
) {
  const formId = useId();
  const onChangeHandler = props.onChange;

  // TODO: Temporary solution for handling resolvers.
  // https://carvago.atlassian.net/browse/T20-70580
  // This will be replaced once zod validation schemas will be implemented everywhere.
  const getResolver = () => {
    if (props.experimentalZodSchema) {
      return zodResolver(props.experimentalZodSchema);
    }
    if (props.schema) {
      return yupResolver(props.schema);
    }
    return undefined;
  };

  const formApi = useForm<TFieldValues>({
    mode: props.mode ?? 'onSubmit',
    resolver: getResolver(),
    defaultValues: props.defaultValues ?? undefined,
    values: props.values ?? undefined,
  });

  const {blocker, navigateWithoutBlocker} = useUnsavedChangesNotification({
    formApi,
    isDisabled: !props.shouldWatchForUnsavedChanges,
  });

  function setErrors(validationErrors: ValidationErrors | null) {
    validationErrors?.forEach((error) => {
      formApi.setError(error.name as Path<TFieldValues>, {message: error.message});
    });
  }

  const onSubmit: SubmitHandler<TFieldValues> = async (values) => {
    await props.onSubmit?.(values, setErrors, formApi.reset);
  };

  const onInvalidSubmit: SubmitErrorHandler<TFieldValues> = async (errors, event) => {
    await props.onInvalidSubmit?.(errors, event);
    if (
      environment.ENV_TYPE === 'dev' ||
      environment.ENV_TYPE === 'test-dev' ||
      environment.ENV_TYPE === 'test-stage'
    ) {
      console.warn(
        'Form submit failed due to these errors: ',
        JSON.stringify(errors, undefined, 2)
      );
    }
  };

  const onChange = useCallback(() => {
    onChangeHandler?.(formApi.getValues(), setErrors, formApi.reset, formApi);
  }, [formApi, onChangeHandler]);

  const control = useMemo(
    () => Object.assign(formApi.control, {formId, onChange}),
    [formApi.control, formId, onChange]
  );

  return (
    <FormProvider {...formApi}>
      <form
        css={props.isFullHeight ? {height: '100%'} : undefined}
        onSubmitCapture={(event) => {
          if (event.target instanceof HTMLElement && event.target.getAttribute('id') === formId) {
            formApi.handleSubmit(onSubmit, onInvalidSubmit)(event);
          }
        }}
        id={formId}
      >
        {props.children(control, formApi, navigateWithoutBlocker)}
      </form>

      <UnsavedChangesNotification blocker={blocker} formApi={formApi} />
    </FormProvider>
  );
}
