import {isNotNil} from 'ramda';

import {ExtendedStatisticsCar} from '../types';

type ExtendedStatisticsCarWithLocation = ExtendedStatisticsCar & {
  location: google.maps.LatLngLiteral;
};

export interface LocationWithCars {
  key: string;
  location: google.maps.LatLngLiteral;
  cars: ExtendedStatisticsCarWithLocation[];
}

export const getLocationsWithCars = (cars: ExtendedStatisticsCar[]): LocationWithCars[] => {
  const carsWithLocation = cars.filter((car) =>
    isNotNil(car.location)
  ) as ExtendedStatisticsCarWithLocation[];

  const groupedCars = carsWithLocation.reduce((acc, car) => {
    const key = `${car.location.lat},${car.location.lon}`;
    if (!acc.has(key)) {
      acc.set(key, []);
    }

    acc.get(key)?.push(car);

    return acc;
  }, new Map<string, ExtendedStatisticsCarWithLocation[]>());

  return [...groupedCars.entries()].map(([key, cars]) => ({
    key,
    location: {lat: cars[0].location.lat, lng: cars[0].location.lon},
    cars,
  }));
};
