import {Button, FormButton, FormControl} from 'platform/components';
import {
  Box,
  Center,
  getSize,
  getValueByDevice,
  HStack,
  Show,
  Space,
  useDevice,
} from 'platform/foundation';
import {useTheme} from 'styled-components';

import {UseFormReturn} from 'react-hook-form';

import {i18n} from '@price-report/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {VehicleFormType} from '../types/VehicleFormType';

interface BottomActionsProps extends TestIdProps {
  step: number;
  onBackClick: () => void;
  formApi: UseFormReturn<VehicleFormType>;
  control: FormControl<VehicleFormType>;
}

export function BottomActions(props: BottomActionsProps) {
  const theme = useTheme();
  const device = useDevice();

  const hasSomeFeature = props.formApi.watch('features')?.length >= 1;
  const isNextDisabled = props.step === 1 && !hasSomeFeature;

  const title =
    props.step === 0
      ? i18n.t('page.create.labels.continue')
      : i18n.t('page.create.labels.complete');

  return (
    <>
      <Space vertical={4} />
      <Box
        position="sticky"
        left={0}
        right={0}
        bottom={8}
        pointerEvents="none"
        data-testid={suffixTestId('wrapper', props)}
      >
        <Center>
          <HStack
            width={`calc(100% - ${getSize(getValueByDevice(device, 8, 8, 16, 16))})`}
            maxWidth={315}
            justify="flex-end"
          >
            <Box
              padding={4}
              backgroundColor="general.white"
              borderRadius={theme.components.Card.borderRadius}
              boxShadow={theme.components.Card.elevation}
              pointerEvents="all"
            >
              <HStack spacing={4}>
                <Show when={props.step > 0}>
                  <Button
                    variant="ghostLink"
                    title={i18n.t('page.create.labels.back')}
                    onClick={props.onBackClick}
                    leftIcon="navigation/arrow_back"
                    data-testid={suffixTestId('backStep', props)}
                  />
                </Show>
                <FormButton
                  key={props.step} // reset button focus state when step changes (cypress snapshot testing)
                  control={props.control}
                  type="submit"
                  title={title}
                  rightIcon="navigation/arrow_forward"
                  isDisabled={isNextDisabled}
                  data-testid={suffixTestId('nextStep', props)}
                />
              </HStack>
            </Box>
          </HStack>
        </Center>
      </Box>
    </>
  );
}
