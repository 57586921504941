import {Show, VStack} from 'platform/foundation';
import styled, {css} from 'styled-components';
import {match} from 'ts-pattern';

import {
  KeyboardEventHandler,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {always, isNil} from 'ramda';
import {isArray, isNumber} from 'ramda-adjunct';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {FormControlProps} from '../../types/FormControlProps';
import {rangeArray} from '../../utils/rangeArray';
import {HelperText} from '../HelperText/HelperText';
import {Label} from '../Label/Label';
import {DisplayValue, SliderTick, SliderValue} from './types';
import {getNonlinearValue} from './utils/getNonlinearValue';
import {getStep} from './utils/getStep';
import {getThumbLabelDisplay} from './utils/getThumbLabelDisplay';
import {roundToStep} from './utils/roundToStep';
import {toPercentage} from './utils/toPercentage';
import {withHalfTicks} from './utils/withHalfTicks';

type AllowMoveTrack<IsRange extends boolean = false> = IsRange extends true ? boolean : false;

export interface SliderProps<IsRange extends boolean = false>
  extends FormControlProps<SliderValue<IsRange>>,
    TestIdProps {
  min: number;
  max: number;
  onlyEdges?: boolean;
  step: number | number[];
  longStepCount?: number;
  isRange?: IsRange | Nullish;
  ticks?: boolean | number | SliderTick[];
  nonlinearTicks?: SliderTick[];
  allowMoveStart?: boolean;
  isAllowInverted?: boolean;
  displayValue?: DisplayValue;
  halfTicks?: boolean;
  allowMoveTrack?: AllowMoveTrack<IsRange>;
  formatValue?: (value: number) => string;
}

const DEFAULT_PROPS = {
  allowMoveStart: true,
  min: 0,
  max: 100,
  longStepCount: 10,
  ticks: true,
  displayValue: 'off',
  halfTicks: false,
  formatValue: (x: number) => `${x}`,
} satisfies Partial<SliderProps>;

export function Slider<IsRange extends boolean = false>(props: SliderProps<IsRange>) {
  const min = props.min ?? DEFAULT_PROPS.min;
  const max = props.max ?? DEFAULT_PROPS.max;
  const longStepCount = props.max ?? DEFAULT_PROPS.longStepCount;
  const ticks = props.ticks ?? DEFAULT_PROPS.ticks;
  const displayValue = props.displayValue ?? DEFAULT_PROPS.displayValue;
  const halfTicks = props.max ?? DEFAULT_PROPS.halfTicks;
  const formatValue = props.formatValue ?? DEFAULT_PROPS.formatValue;

  const trackRef = useRef<HTMLDivElement>(null);
  const startThumbRef = useRef<HTMLDivElement>(null);
  const endThumbRef = useRef<HTMLDivElement>(null);
  const [currentDraggedThumb, setCurrentDraggedThumb] = useState<null | 'start' | 'end'>(null);

  const dragAnchor = match(currentDraggedThumb)
    .with('start', () => startThumbRef.current)
    .with('end', () => endThumbRef.current)
    .otherwise(() => null);

  const [sliderMoveStartPosition, setSliderMoveStartPosition] = useState<number | null>(null);

  const calculateValue = useCallback(
    (clientX: number) => {
      if (!trackRef.current) {
        return;
      }

      const trackRect = trackRef.current.getBoundingClientRect();

      const clickOffset =
        Math.max(trackRect.x, Math.min(trackRect.x + trackRect.width, clientX)) - trackRect.x;

      let newValue = 0;
      if (props.nonlinearTicks) {
        const nonlinearValue = getNonlinearValue({
          min,
          max,
          clickOffset,
          nonlinearTicks: props.nonlinearTicks,
          trackWidth: trackRect.width,
        });

        newValue = roundToStep(nonlinearValue, props.step);
      } else {
        const value = (max - min) * (clickOffset / trackRect.width);

        newValue = roundToStep(min + value, props.step);
      }

      return newValue;
    },
    [max, min, props.nonlinearTicks, props.step]
  );

  const updateValue = useCallback(
    (clientX: number, end: boolean) => {
      if (props.isDisabled) {
        return;
      }

      const newValue = calculateValue(clientX);

      if (isNil(newValue)) {
        return;
      }

      const x = (old: any): number | [number, number] => {
        if (isArray(old)) {
          const newPair: [number, number] = end ? [old[0], newValue] : [newValue, old[1]];

          if (!props.isAllowInverted) {
            if (end && newValue < old[0]) {
              setCurrentDraggedThumb('start');
              return [newValue, old[0]];
            }

            if (!end && newValue > old[1]) {
              setCurrentDraggedThumb('end');
              return [old[1], newValue];
            }
          }

          return newPair;
        }

        return newValue;
      };

      props.onChange?.(x(props.value) as SliderValue<IsRange>);
    },
    [calculateValue, props.onChange, props.value, props.isAllowInverted, props.isDisabled]
  );

  const onTrackClick = (event: MouseEvent) => {
    if (dragAnchor) {
      return;
    }

    if (event.currentTarget.getAttribute('data-eagsliderdragged') === 'true') {
      event.currentTarget.setAttribute('data-eagsliderdragged', 'false');
      return;
    }

    if (!props.isRange) {
      return updateValue(event.clientX, false);
    }

    const thumbs = [...event.currentTarget.querySelectorAll('.eag--slider__thumb')] as [
      Element,
      Element | undefined,
    ];

    const endThumb = thumbs[1];
    if (!endThumb) {
      // actually missing start thumb because of allowMoveStart
      return updateValue(event.clientX, true);
    }

    const startRect = thumbs[0].getBoundingClientRect();
    const startCentre = startRect.left + (startRect.right - startRect.left) / 2;
    const startDiff = Math.abs(event.clientX - startCentre);

    const endRect = endThumb.getBoundingClientRect();
    const endCentre = endRect.left + (endRect.right - endRect.left) / 2;
    const endDiff = Math.abs(event.clientX - endCentre);

    updateValue(event.clientX, endDiff <= startDiff);
  };

  const onDragStart = (thumb: 'start' | 'end') => {
    if (dragAnchor) {
      return;
    }
    trackRef.current?.setAttribute('data-eagsliderdragged', 'true');
    setCurrentDraggedThumb(thumb);
  };

  useEffect(() => {
    if (!dragAnchor || props.isDisabled) {
      return;
    }

    const onDrag = (event: globalThis.MouseEvent) => {
      if (!trackRef.current) {
        return;
      }

      event.preventDefault();
      if (dragAnchor.classList.contains('eag--slider__filled-track')) {
        const trackSizes = dragAnchor.getBoundingClientRect();
        const move = event.clientX - (sliderMoveStartPosition ?? 0);

        const value1 = calculateValue(trackSizes.left + move);
        const value2 = calculateValue(trackSizes.left + trackSizes.width + move);

        setSliderMoveStartPosition(event.clientX);
        props.onChange?.([value1, value2] as SliderValue<IsRange>);
      } else {
        updateValue(event.clientX, currentDraggedThumb === 'end');
      }
    };

    const onDragStop = () => setCurrentDraggedThumb(null);

    const disableGlobalSelect = (event: Event) => {
      event.preventDefault();
    };

    window.addEventListener('mousemove', onDrag);
    window.addEventListener('mouseup', onDragStop);
    window.addEventListener('selectstart', disableGlobalSelect);

    return () => {
      window.removeEventListener('mousemove', onDrag);
      window.removeEventListener('mouseup', onDragStop);
      window.removeEventListener('selectstart', disableGlobalSelect);
    };
  }, [
    dragAnchor,
    updateValue,
    props.isDisabled,
    sliderMoveStartPosition,
    setSliderMoveStartPosition,
    calculateValue,
    props.onChange,
  ]);

  const onKey: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (props.isDisabled) {
      return;
    }

    const targetElement = event.target instanceof Element ? event.target : null;
    const valueIndex = targetElement?.id === 'thumb-end' ? 1 : 0;

    const selectedValue = isArray(props.value) ? props.value[valueIndex] : Number(props.value);

    let delta = 0;
    switch (event.code) {
      case 'Home':
        event.stopPropagation();
        return props.onChange?.(min as SliderValue<IsRange>);
      case 'End':
        event.stopPropagation();
        return props.onChange?.(max as SliderValue<IsRange>);
      case 'ArrowRight':
      case 'ArrowUp':
        event.stopPropagation();
        delta = getStep(selectedValue, props.step);
        break;
      case 'ArrowLeft':
      case 'ArrowDown':
        event.stopPropagation();
        delta = -getStep(selectedValue, props.step, 'prev');
        break;
      case 'PageUp':
        event.stopPropagation();
        delta = isArray(props.step)
          ? getStep(selectedValue, props.step)
          : props.step * longStepCount;
        break;
      case 'PageDown':
        event.stopPropagation();
        delta = -(isArray(props.step)
          ? getStep(selectedValue, props.step, 'prev')
          : props.step * longStepCount);
        break;
    }
    if (isNil(delta) || delta === 0) {
      return;
    }

    if (props.isRange) {
      const isEnd = valueIndex === 1;

      const nextValue: [number, number] = isEnd
        ? [
            (props.value as [number, number])[0],
            Math.max(min, Math.min(max, (props.value as [number, number])[1] + delta)),
          ]
        : [
            Math.max(min, Math.min(max, (props.value as [number, number])[0] + delta)),
            (props.value as [number, number])[1],
          ];

      if (!props.isAllowInverted && nextValue[0] > nextValue[1]) {
        return;
      }

      props.onChange?.(nextValue as SliderValue<IsRange>);
    } else {
      const nextValue = Math.max(min, Math.min(max, (props.value as number) + delta));
      props.onChange?.(nextValue as SliderValue<IsRange>);
    }
  };

  const valueStyles = props.isRange
    ? {
        sliderOffsetStart: toPercentage(
          min,
          max,
          (props.value as [number, number])[0],
          props.nonlinearTicks
        ),
        sliderOffsetEnd: toPercentage(
          min,
          max,
          (props.value as [number, number])[1],
          props.nonlinearTicks
        ),
        sliderFillSize: Math.abs(
          toPercentage(min, max, (props.value as [number, number])[1], props.nonlinearTicks) -
            toPercentage(min, max, (props.value as [number, number])[0], props.nonlinearTicks)
        ),
      }
    : {
        sliderOffset: toPercentage(min, max, props.value as number, props.nonlinearTicks),
      };

  const labelStyles = {
    thumbLabelDisplay: getThumbLabelDisplay(displayValue),
    thumbLabelDisplayHover: getThumbLabelDisplay(displayValue, true),
  };

  const disabledProps = props.isDisabled
    ? ({
        'data-disabled': '',
        'aria-disabled': 'true',
      } as const)
    : {};

  const computedTicks = useMemo<SliderTick[]>(() => {
    if (isNumber(ticks) && ticks < 0) {
      throw new Error('Cannot have negative count of ticks');
    }

    if (isNil(ticks) || ticks === false || ticks === 0) {
      return [];
    }

    let ticksToReturn: SliderTick[] =
      props.nonlinearTicks ?? (isArray(ticks) ? (ticks as SliderTick[]) : []);

    if (!props.nonlinearTicks && (ticks === true || isNumber(ticks))) {
      const ticksCount = ticks === true ? 3 : ticks;
      const step = ticksCount === 1 ? 0 : (max - min) / (ticksCount - 1);

      ticksToReturn = rangeArray(0, ticksCount).map((i) => ({
        value: min + step * i,
      }));
    }

    return halfTicks ? withHalfTicks(ticksToReturn) : ticksToReturn;
  }, [ticks, props.nonlinearTicks, halfTicks, max, min]);

  const onTrackDragStart = (event: MouseEvent) => {
    if (dragAnchor || !props.allowMoveTrack) {
      return;
    }
    trackRef.current?.setAttribute('data-eagsliderdragged', 'true');
    setSliderMoveStartPosition(event.clientX);
    setCurrentDraggedThumb('start');
  };

  const sliderCaseVal = isArray(props.value)
    ? (Number(props.value[1]) ?? 0) < (Number(props.value[0]) ?? 0)
      ? `${valueStyles.sliderOffsetEnd}%`
      : `${valueStyles.sliderOffsetStart}%`
    : '-10px';

  return (
    <VStack>
      <Label
        isRequired={props.isRequired}
        tooltip={props.tooltip}
        data-testid={suffixTestId('label', props)}
      >
        {props.label}
      </Label>
      <StyledWrapper
        data-testid={suffixTestId('sliderWrapper', props)}
        onKeyDown={onKey}
        role="slider"
        {...disabledProps}
      >
        <StyledTrack
          ref={trackRef}
          data-testid={suffixTestId('sliderTrack', props)}
          onClick={onTrackClick}
          {...disabledProps}
        >
          <UnfilledTrack
            data-testid={suffixTestId('sliderUnfilledTrack', props)}
            {...disabledProps}
          />
          <FilledTrack
            data-testid={suffixTestId('sliderFilledTrack', props)}
            onMouseDown={onTrackDragStart}
            $left={sliderCaseVal}
            $value={props.value}
            $allowMoveTrack={props.allowMoveTrack}
            $sliderFillSize={valueStyles.sliderFillSize}
            $sliderOffset={valueStyles.sliderOffset}
            {...disabledProps}
          />

          {props.isRange ? (
            <>
              <Show when={props.allowMoveStart}>
                <ThumbStartWrapper
                  data-testid={suffixTestId('sliderThumbStartWrapper', props)}
                  $sliderOffsetStart={valueStyles.sliderOffsetStart}
                  {...disabledProps}
                >
                  <ThumbStart
                    ref={startThumbRef}
                    data-testid={suffixTestId('sliderThumbStart', props)}
                    onMouseDown={() => onDragStart('start')}
                    aria-valuemin={min}
                    aria-valuemax={max}
                    aria-valuenow={(props.value as number[])[0]}
                    aria-valuetext={formatValue((props.value as [number, number])[0])}
                    tabIndex={props.isDisabled ? -1 : 0}
                    {...disabledProps}
                  >
                    <ThumbLabel
                      className="eag--slider__thumb-label"
                      $thumbLabelDisplay={labelStyles.thumbLabelDisplay}
                    >
                      {formatValue((props.value as number[])[0])}
                    </ThumbLabel>
                  </ThumbStart>
                </ThumbStartWrapper>
              </Show>
              <ThumbEndWrapper
                data-testid={suffixTestId('sliderThumbEndWrapper', props)}
                $sliderOffsetEnd={valueStyles.sliderOffsetEnd}
                {...disabledProps}
              >
                <ThumbEnd
                  id="thumb-end"
                  ref={endThumbRef}
                  data-testid={suffixTestId('sliderThumbEnd', props)}
                  onMouseDown={() => onDragStart('end')}
                  aria-valuemin={min}
                  aria-valuemax={max}
                  aria-valuenow={(props.value as number[])[1]}
                  aria-valuetext={formatValue((props.value as [number, number])[1])}
                  tabIndex={props.isDisabled ? -1 : 0}
                  {...disabledProps}
                >
                  <ThumbLabel
                    className="eag--slider__thumb-label"
                    $thumbLabelDisplay={labelStyles.thumbLabelDisplay}
                  >
                    {formatValue((props.value as number[])[1])}
                  </ThumbLabel>
                </ThumbEnd>
              </ThumbEndWrapper>
            </>
          ) : (
            <ThumbSingleWrapper
              data-testid={suffixTestId('sliderThumbSingleWrapper', props)}
              sliderOffset={valueStyles.sliderOffset}
              {...disabledProps}
            >
              <ThumbSingle
                ref={startThumbRef}
                data-testid={suffixTestId('sliderThumbSingle', props)}
                aria-valuemin={min}
                aria-valuemax={max}
                aria-valuenow={props.value as number}
                aria-valuetext={formatValue(props.value as number)}
                tabIndex={props.isDisabled ? -1 : 0}
                $thumbLabelDisplayHover={labelStyles.thumbLabelDisplayHover}
                onMouseDown={() => onDragStart('start')}
                {...disabledProps}
              >
                <ThumbLabel
                  className="eag--slider__thumb-label"
                  $thumbLabelDisplay={labelStyles.thumbLabelDisplay}
                >
                  {formatValue(props.value as number)}
                </ThumbLabel>
              </ThumbSingle>
            </ThumbSingleWrapper>
          )}
        </StyledTrack>
        {computedTicks.length > 0 && (
          <SliderTicks data-testid={suffixTestId('sliderTicks', props)} {...disabledProps}>
            {computedTicks.map(({value, label, small}, index) => {
              const isFrist = index === 0;
              const isLast = index === computedTicks.length - 1;

              return (
                <StyledSliderTick
                  key={value}
                  data-testid={suffixTestId('sliderTick', props)}
                  $value={value === min ? 'min' : value === max ? 'max' : undefined}
                  $tickOffset={toPercentage(min, max, value, props.nonlinearTicks)}
                  {...disabledProps}
                >
                  <TickIndicator
                    $value={value === min ? 'min' : value === max ? 'max' : undefined}
                    $small={small}
                    data-testid={suffixTestId('sliderTickIndicator', props)}
                    {...disabledProps}
                  />
                  <TickLabel
                    data-testid={suffixTestId('sliderTickLabel', props)}
                    {...disabledProps}
                  >
                    {props.onlyEdges
                      ? isFrist || isLast
                        ? formatValue(value)
                        : null
                      : (label ?? formatValue(value))}
                  </TickLabel>
                </StyledSliderTick>
              );
            })}
          </SliderTicks>
        )}
      </StyledWrapper>
      <Show when={props.errorMessage ?? props.helperText}>
        <HelperText
          errorMessage={props.errorMessage}
          helperText={props.helperText}
          data-testid={suffixTestId('helper', props)}
        />
      </Show>
    </VStack>
  );
}

const StyledWrapper = styled.div`
  padding: 0 10px;
  &:disabled {
    cursor: not-allowed;
  }
`;

const StyledTrack = styled.div`
  position: relative;
  height: 20px;
  margin: 0 10px;
`;

const UnfilledTrack = styled.div`
  position: absolute;
  top: 50%;
  left: -10px;
  width: calc(100% + 20px);
  height: 2px;
  transform: translateY(-50%);
  background-color: ${({theme}) => theme.colors.palettes.neutral[40][100]};
`;

type FilledTrackProps = {
  $left: string;
  $allowMoveTrack?: boolean;
  $sliderFillSize?: number;
  $sliderOffset?: number;
  $value: [number, number] | number;
};

const FilledTrack = styled.div<FilledTrackProps>`
  position: absolute;
  top: 50%;
  left: ${({$left}) => $left};
  height: 4px;
  width: ${({$sliderFillSize, $sliderOffset}) => `${$sliderFillSize ?? $sliderOffset}%`};
  transform: translateY(-50%);
  cursor: ${({$allowMoveTrack}) => ($allowMoveTrack ? 'grab' : undefined)};
  background-color: ${({theme, $value}) =>
    isArray($value)
      ? (Number($value[1]) ?? 0) < (Number($value[0]) ?? 0)
        ? theme.colors.severity.danger
        : theme.colors.general.accent
      : theme.colors.general.accent};
`;

type ThumbStartWrapperProps = {
  $sliderOffsetStart?: number;
};

const ThumbStartWrapper = styled.div<ThumbStartWrapperProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 21px;
  pointer-events: none;
  transform: translateX(${({$sliderOffsetStart}) => `${$sliderOffsetStart}%`});
`;

const ThumbStart = styled.div`
  width: 20px;
  height: 20px;
  transform: translate(-50%, -50%) translateY(calc((-1 * (21px)) / 2));
  pointer-events: auto;
  border-radius: 50%;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    border-color: ${({theme}) => theme.colors.palettes.neutral[20][100]};
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 4px 1px grey;
  }
  background-color: ${({theme}) => theme.colors.palettes.white[10][100]};
  box-shadow: ${(props) => props.theme.shadows.elevation_2};
  border-width: 1px;
  border-style: solid;
  border-color: ${({theme}) => theme.colors.palettes.neutral[40][100]};
  border-image: initial;
`;

type ThumbLabelProps = {
  $thumbLabelDisplay: string;
};

const ThumbLabel = styled.div<ThumbLabelProps>`
  position: absolute;
  bottom: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);
  border-radius: ${({theme}) => theme.radii.xSmall};
  font-weight: ${({theme}) => theme.fontWeights.bold};
  display: ${({$thumbLabelDisplay}) => $thumbLabelDisplay};
  background-color: ${({theme}) => theme.colors.palettes.neutral[900][100]};
  color: ${({theme}) => theme.colors.palettes.white[10][100]};
  padding: 4px 8px;
  font-size: ${({theme}) => theme.fontSizes.text.xSmall};
  line-height: ${({theme}) => theme.lineHeights.text.xSmall};
`;

type ThumbEndWrapperProps = {
  $sliderOffsetEnd?: number;
};

const ThumbEndWrapper = styled.div<ThumbEndWrapperProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 21px;
  pointer-events: none;
  transform: translateX(${({$sliderOffsetEnd}) => `${$sliderOffsetEnd}%`});
`;

const ThumbEnd = styled.div`
  width: 20px;
  height: 20px;
  transform: translate(-50%, -50%) translateY(calc((-1 * (20px + 1px)) / 2));
  pointer-events: auto;
  border-radius: 50%;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    border-color: ${({theme}) => theme.colors.palettes.neutral[20][100]};
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 4px 1px grey;
  }
  background-color: ${({theme}) => theme.colors.palettes.white[10][100]};
  box-shadow: ${(props) => props.theme.shadows.elevation_2};
  border-width: 1px;
  border-style: solid;
  border-color: ${({theme}) => theme.colors.palettes.neutral[40][100]};
  border-image: initial;
`;

type ThumbSingleWrapperProps = {
  sliderOffset?: number;
};

const ThumbSingleWrapper = styled.div<ThumbSingleWrapperProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 21px;
  pointer-events: none;
  transform: translateX(${({sliderOffset}) => `${sliderOffset}%`});
`;

type ThumbSingleProps = {
  $thumbLabelDisplayHover: string;
};

const ThumbSingle = styled.div<ThumbSingleProps>`
  width: 20px;
  height: 20px;
  transform: translate(-50%, -50%) translateY(calc((-1 * (21px)) / 2));
  pointer-events: auto;
  border-radius: 50%;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    border-color: ${({theme}) => theme.colors.palettes.neutral[20][100]};
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 4px 1px grey;
  }
  &:hover {
    .eag--slider__thumb-label {
      display: ${({$thumbLabelDisplayHover}) => $thumbLabelDisplayHover};
    }
  }
  background-color: ${({theme}) => theme.colors.palettes.white[10][100]};
  box-shadow: ${(props) => props.theme.shadows.elevation_2};
  border-width: 1px;
  border-style: solid;
  border-color: ${({theme}) => theme.colors.palettes.neutral[40][100]};
  border-image: initial;
`;

const SliderTicks = styled.div`
  position: relative;
  margin: 0 10px;
  height: 24px;
`;

type SliderTickProps = {
  $tickOffset: number;
  $value: 'min' | 'max' | undefined;
};

const getSliderTickPosition = (value: 'min' | 'max' | undefined, tickOffset: number) =>
  match(value)
    .with(
      'min',
      always(css<SliderTickProps>`
        transition: none;
        left: -10px;
      `)
    )
    .with(
      'max',
      always(css`
        transform: translateX(-100%);
        left: calc(${tickOffset}% + 10px);
      `)
    )
    .with(
      undefined,
      always(css`
        transform: translateX(-50%);
        left: ${tickOffset}%;
      `)
    )
    .exhaustive();

const StyledSliderTick = styled.div<SliderTickProps>`
  position: absolute;
  top: 0;
  ${({$value, $tickOffset}) => getSliderTickPosition($value, $tickOffset)}
`;

const getTickIndicatorPosition = (value: 'min' | 'max' | undefined) =>
  match(value)
    .with(
      'min',
      always(css`
        left: 0;
      `)
    )
    .with(
      'max',
      always(css`
        left: auto;
        right: 0;
      `)
    )
    .with(
      undefined,
      always(css`
        left: 50%;
      `)
    )
    .exhaustive();

type TickIndicatorProp = {
  $value: 'min' | 'max' | undefined;
  $small?: boolean;
};

const TickIndicator = styled.div<TickIndicatorProp>`
  position: absolute;
  transform: translateY(50%);
  height: ${({$small}) => ($small ? '8px' : '12px')};
  width: 1px;
  bottom: calc(100% + 10px);
  background-color: ${({theme}) => theme.colors.palettes.neutral[40][100]};

  ${({$value}) => getTickIndicatorPosition($value)}
`;

const TickLabel = styled.div`
  white-space: nowrap;
`;
