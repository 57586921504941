import {Button, Flag, useIntersectionObserver} from 'platform/components';
import {HStack, Integer, Show, ThemeColorTextPath} from 'platform/foundation';
import styled from 'styled-components';

import {useState} from 'react';

import {isNotNil, not, sort} from 'ramda';

import {i18n} from '@price-report/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {useCatalogue} from '../../../hooks/useCatalogue';
import {ListText} from './ListText';

interface ListProps extends TestIdProps {
  isRow?: boolean;
  compareWith?: string[] | null;
  values?: string[] | null;
  spacing: Integer;
  textColor: ThemeColorTextPath;
  firstWhite?: boolean;
  expandable?: boolean;
  sortable?: boolean;
  isMyVehicle?: boolean;
  isBold?: boolean;
}

export function FeatureList(props: ListProps) {
  const {getFeatureLabel, getFeaturePriority} = useCatalogue();

  // Features with lower priority on top, features with undefined priority (=0) on bottom, sorted alphabetically
  const featureCompare = (a: string, b: string) => {
    const aPriority = getFeaturePriority(a);
    const bPriority = getFeaturePriority(b);

    if (aPriority === bPriority) {
      return getFeatureLabel(a).localeCompare(getFeatureLabel(b));
    }

    if (aPriority === 0) {
      return 1;
    }
    if (bPriority === 0) {
      return -1;
    }

    return aPriority - bPriority;
  };

  const vehicleFeatures =
    props.sortable && isNotNil(props.values) ? sort(featureCompare, props.values) : props.values;

  const [expanded, setExpanded] = useState(!props.expandable);
  const [hiddenFeaturesCount, setHiddenFeaturesCount] = useState<number>(0);

  const featuresRef = useIntersectionObserver((entries) =>
    setHiddenFeaturesCount(entries.filter((entry) => !entry.isIntersecting).length)
  );

  const colorOffset = props.firstWhite ? 1 : 0;
  const valuesLength = props.values?.length ?? 0;
  const showLessButton = props.expandable && expanded;

  const handleExpand = () => setExpanded(!expanded);

  const isMissing = (value: string) => {
    if (!props.compareWith) {
      return false;
    }

    return !props.compareWith.includes(value);
  };

  return (
    <HStack spacing={props.spacing}>
      <Wrapper $isRow={props.isRow} $spacing={props.spacing} $expanded={expanded}>
        {vehicleFeatures?.map((feature, index) => {
          if (props.isRow) {
            return (
              <div
                key={feature}
                ref={(el) => {
                  featuresRef.current[index] = el;
                }}
              >
                <HStack spacing={props.spacing}>
                  <Flag
                    label={getFeatureLabel(feature)}
                    isSubtle
                    colorScheme="blue"
                    data-testid={suffixTestId(`value[${index}]`, props)}
                  />
                  <Show
                    when={
                      !expanded &&
                      hiddenFeaturesCount > 0 &&
                      index === vehicleFeatures.length - hiddenFeaturesCount - 1
                    }
                  >
                    <Button
                      title={hiddenFeaturesCount + ' +'}
                      size="small"
                      onClick={handleExpand}
                      data-testid={suffixTestId('moreButton', props)}
                    />
                  </Show>
                </HStack>
              </div>
            );
          } else {
            return (
              <ListText
                key={feature}
                value={getFeatureLabel(feature)}
                textColor={props.textColor}
                isRow={props.isRow}
                showBackground={not((index + colorOffset) % 2)}
                showDot={index + 1 < valuesLength}
                isMyVehicle={props.isMyVehicle}
                isMissing={isMissing(feature)}
                isBold={props.isBold}
                data-testid={suffixTestId(`value[${index}]`, props)}
              />
            );
          }
        })}
        <Show when={showLessButton}>
          <Button
            title={i18n.t('entity.list.hide')}
            size="small"
            onClick={handleExpand}
            data-testid={suffixTestId('moreButton', props)}
          />
        </Show>
      </Wrapper>
    </HStack>
  );
}

// TODO: T20-71402 - change to responsive components from platform
const Wrapper = styled.div<{$isRow?: boolean; $spacing: Integer; $expanded: boolean}>`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: ${({$isRow}) => ($isRow ? 'row' : 'column')};
  gap: ${({theme, $spacing, $isRow}) => ($isRow ? theme.getSize($spacing) : '0')};
  max-height: ${({$expanded, $isRow, theme}) =>
    $expanded || !$isRow ? 'unset' : theme.getSize(6)};
  overflow: hidden;
  @media only screen and (max-width: ${({theme}) => theme.breakPoints.SMALL_UP}) {
    flex-direction: row;
    gap: ${({theme, $spacing}) => theme.getSize($spacing)};
    max-height: ${({$expanded, theme}) => ($expanded ? 'unset' : theme.getSize(6))};
  }
`;
