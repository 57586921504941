import {Box, Center, HStack, Text, VStack} from 'platform/foundation';

import {i18n} from '@price-report/shared';

export function ColumnLayoutDescription() {
  return (
    <Box width={35} paddingTop={2} flexShrink={0} position="sticky" left={0} zIndex="SIDEBAR">
      <Box
        height="100%"
        backgroundColor="palettes.neutral.10.100"
        borderRight="1px solid"
        borderBottom="1px solid"
        borderColor="general.separator"
      >
        <VStack height="100%">
          <Box height={24} />
          <Box height={16} padding={2} borderBottom="1px solid" borderColor="general.separator">
            <Text size="xSmall" color="secondary">
              {i18n.t('entity.table.title')}
            </Text>
          </Box>
          <Box height={18} padding={2} borderBottom="1px solid" borderColor="general.separator">
            <Text size="xSmall" color="secondary">
              {i18n.t('entity.table.price')}
            </Text>
          </Box>
          <Box height={7} padding={2} borderBottom="1px solid" borderColor="general.separator">
            <Center height="100%" justify="flex-start">
              <Text size="xSmall" color="secondary">
                {i18n.t('entity.table.position')}
              </Text>
            </Center>
          </Box>
          <Box height={14} padding={2} borderBottom="1px solid" borderColor="general.separator">
            <Text size="xSmall" color="secondary">
              {i18n.t('entity.table.featureLevel')}
            </Text>
          </Box>
          <Box height={14} padding={2} borderBottom="1px solid" borderColor="general.separator">
            <Text size="xSmall" color="secondary">
              {i18n.t('entity.table.mileage')}
            </Text>
          </Box>
          <Box height={14} padding={2} borderBottom="1px solid" borderColor="general.separator">
            <Text size="xSmall" color="secondary">
              {i18n.t('entity.table.firstRegistration')}
            </Text>
          </Box>
          <Box paddingHorizontal={2} borderBottom="1px solid" borderColor="general.separator">
            <HStack height={7} align="center">
              <Text size="xSmall" color="secondary">
                {i18n.t('entity.charts.labels.daysOnStock')}
              </Text>
            </HStack>
            <HStack height={7} align="center">
              <Text size="xSmall" color="secondary">
                {i18n.t('entity.charts.labels.seller')}
              </Text>
            </HStack>
          </Box>
          <Box paddingHorizontal={2} borderBottom="1px solid" borderColor="general.separator">
            <HStack height={7} align="center">
              <Text size="xSmall" color="secondary">
                {i18n.t('entity.table.transmission')}
              </Text>
            </HStack>
            <HStack height={7} align="center">
              <Text size="xSmall" color="secondary">
                {i18n.t('entity.table.fuel')}
              </Text>
            </HStack>
            <HStack height={7} align="center">
              <Text size="xSmall" color="secondary">
                {i18n.t('entity.table.power')}
              </Text>
            </HStack>
            <HStack height={7} align="center">
              <Text size="xSmall" color="secondary">
                {i18n.t('entity.table.drive')}
              </Text>
            </HStack>
            <HStack height={7} align="center">
              <Text size="xSmall" color="secondary">
                {i18n.t('entity.table.body')}
              </Text>
            </HStack>
          </Box>
          <Box flex={1} padding={2}>
            <Text size="xSmall" color="secondary">
              {i18n.t('entity.table.features')}
            </Text>
          </Box>
        </VStack>
      </Box>
    </Box>
  );
}
